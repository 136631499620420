import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgTrans = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 12 12"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <mask
            id="trans_svg__a"
            width={12}
            height={12}
            x={0}
            y={0}
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
        >
            <path fill="#fff" d="M0 0h12v12H0z" />
        </mask>
        <g mask="url(#trans_svg__a)">
            <path
                fill="url(#trans_svg__b)"
                d="M10.819 11.726H1.27a.506.506 0 0 1-.529-.472V9.878c0-.26.252-.29.53-.473l4.773-2.846 4.774 2.85c.31.195.528.214.528.472v1.377a.506.506 0 0 1-.528.468"
            />
            <path fill="url(#trans_svg__c)" d="M5.28 6.409h1.59v2.355H5.28z" />
            <path
                fill="url(#trans_svg__d)"
                d="M6.195.191c1.988 0 3.161 2.854 2.963 6.567-2.363.168-6.162.135-6.162.135S2.483.19 6.196.19"
            />
            <path
                fill="#4B201C"
                fillOpacity={0.34}
                d="M6.045 7.155c1.035 0 1.875-1.459 1.875-3.259S7.08.637 6.045.637 4.17 2.097 4.17 3.897s.84 3.258 1.875 3.258"
            />
            <path
                fill="url(#trans_svg__e)"
                d="M6.143 7.155c1.035 0 1.875-1.459 1.875-3.259S7.178.637 6.143.637s-1.875 1.46-1.875 3.26.839 3.258 1.875 3.258"
            />
            <path
                fill="url(#trans_svg__f)"
                d="M6.199.191c-1.875 0-2.674 1.74-3 3.447l1.065.337A5.8 5.8 0 0 1 6.195 2.88c1.125-.375.971-1.192.971-1.192a1.76 1.76 0 0 1 .791 1.368c.064.548.27.173.75-.319C8.235 1.177 7.357.192 6.2.192"
            />
            <ellipse
                cx={6.025}
                cy={4.5}
                fill="url(#trans_svg__g)"
                rx={2.375}
                ry={4}
            />
            <path
                fill="#E1C3C2"
                d="M6.874 11.34V7.391l3.847 2.082c.086.052.169.093.24.127.195.098.195.109.195.165v1.313a.31.31 0 0 1-.337.266H6.874zm-5.603 0a.31.31 0 0 1-.337-.266V9.76c0-.056 0-.075.206-.168q.121-.058.236-.128l3.919-2.111v3.986z"
            />
            <path
                fill="url(#trans_svg__h)"
                d="M6.874 11.34V7.391l3.847 2.082c.087.052.17.093.24.127.195.098.195.109.195.165v1.313a.31.31 0 0 1-.337.266H6.874zm-5.603 0a.31.31 0 0 1-.337-.266V9.76c0-.056 0-.075.206-.168q.122-.058.236-.128l3.92-2.111v3.986z"
            />
            <path
                fill="#9D4633"
                d="M5 7.416a.3.3 0 1 0-.3-.52.3.3 0 0 0 .3.52"
            />
            <path
                fill="#9D4633"
                d="M5.296 7.934a.3.3 0 1 0-.3-.52.3.3 0 0 0 .3.52M7.17 7.934a.3.3 0 1 0-.3-.52.3.3 0 0 0 .3.52"
            />
            <path
                fill="#9D4633"
                d="M7.467 7.42a.3.3 0 1 0-.3-.52.3.3 0 0 0 .3.52"
            />
            <path fill="url(#trans_svg__i)" d="M5.28 8.764h1.59v2.962H5.28z" />
        </g>
        <defs>
            <linearGradient
                id="trans_svg__b"
                x1={6.045}
                x2={6.045}
                y1={6.559}
                y2={11.727}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#C7E6D3" />
                <stop offset={1} stopColor="#258B59" />
            </linearGradient>
            <linearGradient
                id="trans_svg__c"
                x1={5.28}
                x2={6.874}
                y1={7.586}
                y2={7.586}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#A57C4B" />
                <stop offset={0.07} stopColor="#BE9461" />
                <stop offset={0.14} stopColor="#D2A773" />
                <stop offset={0.22} stopColor="#E0B480" />
                <stop offset={0.32} stopColor="#E9BC87" />
                <stop offset={0.49} stopColor="#EBBE89" />
                <stop offset={0.68} stopColor="#E9BC87" />
                <stop offset={0.78} stopColor="#E1B480" />
                <stop offset={0.86} stopColor="#D3A874" />
                <stop offset={0.93} stopColor="#C09663" />
                <stop offset={0.99} stopColor="#A87F4D" />
                <stop offset={1} stopColor="#A37A49" />
            </linearGradient>
            <linearGradient
                id="trans_svg__d"
                x1={6.079}
                x2={6.079}
                y1={0.191}
                y2={6.893}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.56} stopColor="#6E3B35" />
                <stop offset={0.72} stopColor="#6A3430" />
                <stop offset={0.95} stopColor="#5D2221" />
                <stop offset={1} stopColor="#5A1D1D" />
            </linearGradient>
            <linearGradient
                id="trans_svg__f"
                x1={5.966}
                x2={5.966}
                y1={0.191}
                y2={3.975}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#CF8F5E" />
                <stop offset={0.04} stopColor="#C6875A" />
                <stop offset={0.2} stopColor="#A66C4D" />
                <stop offset={0.37} stopColor="#8D5642" />
                <stop offset={0.55} stopColor="#7C473B" />
                <stop offset={0.73} stopColor="#713E36" />
                <stop offset={0.95} stopColor="#6E3B35" />
            </linearGradient>
            <linearGradient
                id="trans_svg__h"
                x1={6.045}
                x2={6.045}
                y1={7.354}
                y2={11.345}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#258B59" />
                <stop offset={1} stopColor="#C7E6D3" />
            </linearGradient>
            <linearGradient
                id="trans_svg__i"
                x1={6.079}
                x2={6.079}
                y1={11.726}
                y2={8.764}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#9D9FA2" />
                <stop offset={0.49} stopColor="#fff" />
                <stop offset={0.68} stopColor="#FDFDFD" />
                <stop offset={0.77} stopColor="#F5F5F6" />
                <stop offset={0.85} stopColor="#E7E8E9" />
                <stop offset={0.92} stopColor="#D4D5D7" />
                <stop offset={0.97} stopColor="#BCBEC0" />
                <stop offset={1} stopColor="#AEB0B3" />
            </linearGradient>
            <radialGradient
                id="trans_svg__e"
                cx={0}
                cy={0}
                r={1}
                gradientTransform="matrix(1.87125 0 0 3.23726 6.143 3.918)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.49} stopColor="#EBBE89" />
                <stop offset={0.66} stopColor="#E9BC87" />
                <stop offset={0.75} stopColor="#E1B480" />
                <stop offset={0.82} stopColor="#D3A874" />
                <stop offset={0.88} stopColor="#C09663" />
                <stop offset={0.94} stopColor="#A87F4D" />
                <stop offset={0.95} stopColor="#A37A49" />
                <stop offset={0.99} stopColor="#A37A49" />
            </radialGradient>
            <radialGradient
                id="trans_svg__g"
                cx={0}
                cy={0}
                r={1}
                gradientTransform="matrix(2 -1 .59375 1.1875 5.5 1.5)"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#D2AB8D" />
                <stop offset={0.703} stopColor="#A66C4E" stopOpacity={0} />
            </radialGradient>
        </defs>
    </svg>
)
const ForwardRef = forwardRef(SvgTrans)
const Memo = memo(ForwardRef)
export default Memo
