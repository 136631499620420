import { gettext } from "@multimediallc/web-utils"
import { Typography } from "../../common"
import "./OneClickFlowSuccessPage.scss"

interface OneClickFlowSuccessPageProps {
    tokenAmount: number
}

export function OneClickFlowSuccessPage({
    tokenAmount,
}: OneClickFlowSuccessPageProps) {
    const i18n = {
        purchaseSuccessful: gettext("Purchase successful"),
        serveBetter: gettext(
            'In order to serve you better, please take a minute to give us your <a href="https://www.surveymonkey.com/r/6WLZYGV" target="_blank">billing feedback</a>.',
        ),
        tenMinutes: gettext(
            "It may take up to 10 minutes to show up in your account.",
        ),
        addedToTheAccount: gettext("have been added to your account"),
    }
    return (
        <>
            <Typography
                className="one-click-flow__scene-title"
                size="xlpx"
                weight="bold"
            >
                {i18n.purchaseSuccessful}
            </Typography>
            <div className="one-click-flow__success-page">
                <Typography
                    size="xlpx"
                    weight="bold"
                    className="one-click-flow__success-page__tokens"
                >
                    {`${tokenAmount} tokens`}
                </Typography>
                <Typography
                    size="mdpx"
                    className="one-click-flow__success-page__text"
                >
                    {i18n.addedToTheAccount}
                </Typography>
                <Typography
                    size="smpx"
                    className="one-click-flow__success-page__middle secondary-text"
                >
                    {i18n.tenMinutes}
                </Typography>
                <Typography
                    size="smpx"
                    className="one-click-flow__success-page__footer secondary-text"
                    dangerouslySetInnerHTML={{ __html: i18n.serveBetter }}
                />
            </div>
        </>
    )
}
