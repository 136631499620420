import { type ComponentPropsWithoutRef, useState } from "react"
import "./PurchaseMethodDescription.scss"
import { gettext } from "@multimediallc/web-utils"
import { useAppContext } from "../../../hooks/appContext"
import { useAppSelector } from "../../../store/hooks"
import { Link, TextInput, Typography } from "../../common"
import { InputCopy } from "../../common/molecules/InputCopy"
import { CRYPTO_MIN_TOKENS } from "../constants"
import type {
    CryptoPayMethod,
    Paymethod,
} from "../../../store/purchasePage/purchasePageSlice"

interface PurchaseMethodDescriptionProps
    extends ComponentPropsWithoutRef<"div"> {
    selectedPayMethod: Paymethod
}

const i18n = {
    tokenAmount: gettext("Token Amount:"),
    cost: gettext("Cost:"),
    email: gettext("Email address: (required for refunds)"),
    withPaygarden: gettext("Pay with PayGarden"),
    withWire: gettext("Wire Transfer Instructions:"),
    referAndEarn: gettext("Refer and Earn:"),
    paygardenDesc: gettext(
        "Purchase tokens anonymously using 70+ retail brand gift cards. No credit card required.",
    ),
    verifyStatusOfInvoices: gettext(
        "View the status of your <a href='/tipping/coinsmart_invoices/' target='_blank' rel=\"noopener noreferrer\">invoices</a>.",
    ),
    cryptoQuestions: gettext(
        "Crypto Questions? <a href='mailto:support@chaturbate.com?subject=Crypto%20Payment%20Support' target='_blank' rel=\"noopener noreferrer\">contact support</a>.",
    ),
    wireDesc:
        // eslint-disable-next-line @multimediallc/no-template-gettext
        gettext(`The minimum purchase for wire transfer is $250.00 USD. All
            purchases are credited at $0.08 per token (For example, a wire
            transfer of $1,000.00 USD will be credited 12,500 tokens).
            Please contact <a href='mailto:support@chaturbate.com' rel=\"noopener noreferrer\">support@chaturbate.com</a> for wire transfer
            instructions.`),
    checkStats: gettext("Check stats"),
    shareLink: gettext("Share the link code below with your friends:"),
    earnUpTo: gettext(
        "Earn up to 10 tokens for every registered user and 500 tokens for users who broadcast. Broadcasters must earn $20.00 before they qualify.",
    ),
    pendingInvoices: gettext(
        "You have <a href='/tipping/coinsmart_invoices/' target='_blank' rel=\"noopener noreferrer\">Pending Invoices.</a>",
    ),
}

function PaygardenDescription() {
    return (
        <div className="token-method-description paygarden">
            <Typography size="smpx">{i18n.withPaygarden}</Typography>
            <Typography size="xspx">{i18n.paygardenDesc}</Typography>
        </div>
    )
}

function WireDescription() {
    return (
        <div className="token-method-description wire">
            <Typography size="smpx">{i18n.withWire}</Typography>
            <Typography
                size="xspx"
                dangerouslySetInnerHTML={{ __html: i18n.wireDesc }}
            />
        </div>
    )
}

function ReferFriendDescription() {
    const shareUrl = useAppSelector((state) => state.purchasePage.shareUrl)
    const { is_mobile: isMobile } = useAppContext()?.context
    return (
        <div className="token-method-description refer-friend">
            <Typography size="smpx">Refer and Earn:</Typography>
            <Typography size="xspx">{i18n.earnUpTo}</Typography>
            <Typography size="xspx">{i18n.shareLink}</Typography>
            <InputCopy text={shareUrl} />
            {!isMobile && (
                <Link href="/affiliates/stats/" target="_blank">
                    {i18n.checkStats}
                </Link>
            )}
        </div>
    )
}

function CryptoDescription({
    selectedPayMethod,
}: PurchaseMethodDescriptionProps) {
    const [cost, setCost] = useState(
        (Number.parseFloat(CRYPTO_MIN_TOKENS) * 0.08).toFixed(2),
    )
    const ctyptoPaymethodParams = selectedPayMethod as CryptoPayMethod
    const hasPendingInvoices = ctyptoPaymethodParams.pending_invoice_count > 0
    const defaultEmail = ctyptoPaymethodParams.email_initial
    return (
        <div className="cryptoDescription">
            {hasPendingInvoices && (
                <Typography
                    className="cryptoDescription__pendingInvoices"
                    size="smpx"
                    dangerouslySetInnerHTML={{ __html: i18n.pendingInvoices }}
                />
            )}
            <div className="cryptoDescription__costWrapper">
                <div className="cryptoDescription__token_amount">
                    <Typography
                        htmlFor="numTokens"
                        className="cryptoDescription__label"
                        size="xspx"
                        component="label"
                    >
                        {i18n.tokenAmount}
                    </Typography>
                    <TextInput
                        name="numTokens"
                        id="numTokens"
                        data-testid="numTokens"
                        type="number"
                        min={250}
                        max={20000}
                        defaultValue={CRYPTO_MIN_TOKENS}
                        required
                        onChange={(e) => {
                            const numTokens = Number.parseFloat(e.target.value)
                            setCost((numTokens * 0.08).toFixed(2))
                        }}
                    />
                </div>
                <div className="cryptoDescription__cost">
                    <Typography size="xspx">{i18n.cost}</Typography>
                    <Typography size="smpx">{`$${cost} USD`}</Typography>
                </div>
            </div>
            <div className="cryptoDescription__emailField">
                <Typography
                    className="cryptoDescription__label"
                    component="label"
                    htmlFor="email"
                    size="xspx"
                >
                    {i18n.email}
                </Typography>
                <TextInput
                    id="email"
                    data-testid="email"
                    type="email"
                    name="email"
                    pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                    defaultValue={defaultEmail}
                    required
                />
            </div>
            <div className="cryptoDescription__info">
                <Typography
                    size="xspx"
                    dangerouslySetInnerHTML={{
                        __html: i18n.verifyStatusOfInvoices,
                    }}
                />
                <Typography
                    size="xspx"
                    dangerouslySetInnerHTML={{ __html: i18n.cryptoQuestions }}
                />
            </div>
        </div>
    )
}

export function PurchaseMethodDescription({
    selectedPayMethod,
}: PurchaseMethodDescriptionProps) {
    if (selectedPayMethod.type === "paygarden") {
        return <PaygardenDescription />
    }
    if (selectedPayMethod.type === "refer_friend") {
        return <ReferFriendDescription />
    }
    if (selectedPayMethod.type === "wire") {
        return <WireDescription />
    }
    if (selectedPayMethod.type === "cryptopayment") {
        return <CryptoDescription selectedPayMethod={selectedPayMethod} />
    }
    return null
}
