import { gettext } from "@multimediallc/web-utils"
import { createSlice, isAnyOf } from "@reduxjs/toolkit"
import { createApi } from "@reduxjs/toolkit/query/react"
import { PAYMETHOD_CRYPTOPAYMENT } from "../../components/purchase_page/constants"
import {
    getProperPaymentPlans,
    isAffectedBySpendingLimit,
} from "../../components/purchase_page/utils"
import { cbBaseQuery, isCustomRtkError } from "../../utils/myFetch"

const i18n = {
    spendingLimitError: gettext(
        "You’ve reached your spending limit for Credit Card purchases.",
    ),
    cryptoSpendingLimitError: gettext(
        "You’ve reached your spending limit for Crypto purchases.",
    ),
    cryptoTooManyPendingInvoicesError: gettext(
        "You've reached the limit on pending cryptocurrency invoices. Please fulfill an existing invoice before attempting another token purchase with cryptocurrency.",
    ),
    transactionDeclinedError: gettext("Transaction Declined"),
    errorProcessingRequest: gettext(
        "There was an error processing your request. Please try again later.",
    ),
}
export type PaymentPlanKeys = "creditCard" | "paypal" | "epoch" | "alternative"
export interface PaymentPlan {
    product_id: string
    tokens: number
    cost: number
    percent_bonus: number
    is_default: boolean
    is_initial: boolean
}

export type Paymethod =
    | SavedCardPayMethod
    | CommonPaymentMethod
    | CryptoPayMethod
export interface CommonPaymentMethod {
    uuid: string
    type: string
    is_initial: boolean
    is_default: boolean
    disabled?: boolean
}

export interface SavedCardPayMethod extends CommonPaymentMethod {
    id: string
    suspended: boolean
    cvv2_required: boolean
    card_type: string
    cc_last_4: string
    cc_expire_string: string
}

export interface CryptoPayMethod extends CommonPaymentMethod {
    email_initial: string
    pending_invoice_count: number
    spending_limit_reached: boolean
    has_too_many_pending_invoices: boolean
}
interface IGetPurchasePageDataResponse {
    pay_methods: (SavedCardPayMethod | CommonPaymentMethod | CryptoPayMethod)[]
    eligible_plans: PaymentPlan[]
    eligible_plans_for_epoch_paypal: PaymentPlan[]
    eligible_plans_for_epoch: PaymentPlan[]
    eligible_plans_for_alternative_payments: PaymentPlan[]
    tokens_allowed_for_purchase: number
    share_url: string
}

export const purchasePageApi = createApi({
    baseQuery: cbBaseQuery({
        baseUrl: "/api/ts/tipping/",
    }),
    tagTypes: ["purchasePageData"],
    reducerPath: "purchasePageApi",
    endpoints: (build) => ({
        getPurchaseData: build.query<
            IGetPurchasePageDataResponse,
            { source: string }
        >({
            query: (arg) => ({
                url: `purchase-page-data/?source=${arg.source}/`,
                method: "GET",
            }),
            forceRefetch: () => true,
        }),
        deleteSavedCard: build.mutation<null, { id: string }>({
            query: (arg) => ({
                url: `oneclicks/?id=${arg.id}`,
                method: "DELETE",
            }),
        }),
        setDefaultSavedCard: build.mutation<void, { id: string }>({
            query: (arg) => ({
                url: `oneclicks/default/`,
                method: "POST",
                data: {
                    id: arg.id.toString(),
                },
            }),
        }),
        makeOneClickPurchase: build.mutation<
            { success: boolean; error: string | null; validation_err: string },
            { cvv2: string; subId: string; productId: string }
        >({
            query: (arg) => ({
                url: `ajax-oneclick-purchase/`,
                method: "POST",
                data: {
                    payMethod: "savedcard",
                    cvv2: arg.cvv2,
                    savedcard_sub_id: arg.subId ?? "",
                    productID: arg.productId,
                },
            }),
        }),
        makePurchase: build.mutation<
            { success: boolean; redirect_url: string },
            {
                payMethod: string
                productID?: string
                productIDAlternativePayments?: string
                email?: string
                numTokens?: string
            }
        >({
            query: (arg) => ({
                url: `purchase-tokens/`,
                method: "POST",
                data: {
                    payMethod: arg.payMethod,
                    productID: arg.productID ?? "",
                    productIDAlternativePayments:
                        arg.productIDAlternativePayments ?? "",
                    email_field: arg?.email ?? "",
                    amount_field: arg?.numTokens ?? "",
                },
            }),
        }),
    }),
})

interface PurchasePageState {
    loading: boolean
    paymentPlans: Record<PaymentPlanKeys, PaymentPlan[]>
    payMethods: Paymethod[]
    tokensAllowedForPurchase: number
    shareUrl: string
    error: string
    cryptoError: string
    page: string
    redirect_url: string
}

const initialState: PurchasePageState = {
    loading: false,
    paymentPlans: {
        creditCard: [],
        paypal: [],
        alternative: [],
        epoch: [],
    },
    payMethods: [],
    tokensAllowedForPurchase: 0,
    shareUrl: "",
    error: "",
    cryptoError: "",
    page: "main",
    redirect_url: "",
}

const purchasePageSlice = createSlice({
    name: "purchasePage",
    initialState: initialState,
    reducers: {
        selectPaymethod(state, action) {
            state.payMethods = state.payMethods.map((method) => ({
                ...method,
                is_default:
                    (method as CommonPaymentMethod).uuid === action.payload,
            }))
        },
        selectPaymentPlan(state, action) {
            const { payMethod, productId } = action.payload

            const paymethodKey = getProperPaymentPlans(payMethod)
            if (!paymethodKey) {
                return
            }
            state.paymentPlans[paymethodKey] = state.paymentPlans[
                paymethodKey
            ].map((plan) => ({
                ...plan,
                is_default: plan.product_id === productId,
            }))
        },
        setPage(state, action) {
            state.page = action.payload
        },
    },
    extraReducers(builder) {
        builder.addMatcher(
            purchasePageApi.endpoints.getPurchaseData.matchFulfilled,
            (state, action) => {
                state.loading = false
                const {
                    pay_methods,
                    eligible_plans,
                    eligible_plans_for_alternative_payments,
                    eligible_plans_for_epoch,
                    eligible_plans_for_epoch_paypal,
                    tokens_allowed_for_purchase,
                    share_url,
                } = action.payload

                state.payMethods = pay_methods

                state.paymentPlans.alternative =
                    eligible_plans_for_alternative_payments ?? []
                state.paymentPlans.creditCard = eligible_plans ?? []
                state.paymentPlans.epoch = eligible_plans_for_epoch ?? []
                state.paymentPlans.paypal =
                    eligible_plans_for_epoch_paypal ?? []
                state.tokensAllowedForPurchase = tokens_allowed_for_purchase
                state.shareUrl = share_url
                const cryptoPayMethod = pay_methods.find(
                    (method) => method.type === PAYMETHOD_CRYPTOPAYMENT,
                )

                if (cryptoPayMethod) {
                    if (
                        Boolean(
                            (cryptoPayMethod as CryptoPayMethod)
                                .spending_limit_reached,
                        ) ||
                        Boolean(
                            (cryptoPayMethod as CryptoPayMethod)
                                .has_too_many_pending_invoices,
                        )
                    ) {
                        state.payMethods = state.payMethods.map((method) => {
                            if (method.type === PAYMETHOD_CRYPTOPAYMENT) {
                                return {
                                    ...method,
                                    disabled: true,
                                }
                            }
                            return method
                        })
                    }
                    if (
                        (cryptoPayMethod as CryptoPayMethod)
                            .has_too_many_pending_invoices
                    ) {
                        state.cryptoError =
                            i18n.cryptoTooManyPendingInvoicesError
                    }

                    if (
                        (cryptoPayMethod as CryptoPayMethod)
                            .spending_limit_reached
                    ) {
                        state.cryptoError = i18n.cryptoSpendingLimitError
                    }
                }
                if (tokens_allowed_for_purchase <= 0) {
                    state.error = i18n.spendingLimitError
                    state.payMethods = state.payMethods.map((method) => {
                        if (isAffectedBySpendingLimit(method.type)) {
                            return {
                                ...method,
                                disabled: true,
                            }
                        }
                        return method
                    })
                }
            },
        )
        builder.addMatcher(
            purchasePageApi.endpoints.deleteSavedCard.matchFulfilled,
            (state, action) => {
                const wasSelected = state.payMethods.find(
                    (method) =>
                        (method as SavedCardPayMethod)?.id ===
                        action.meta.arg.originalArgs.id,
                ) as SavedCardPayMethod
                state.payMethods = state.payMethods.filter((method) => {
                    if (method.type === "savedcard") {
                        return (
                            (method as SavedCardPayMethod).id !==
                            action.meta.arg.originalArgs.id
                        )
                    }
                    return true
                })
                if (wasSelected?.is_default) {
                    state.payMethods[0].is_default = true
                }
                if (state.page === "cvv") {
                    state.page = "main"
                }
            },
        )
        builder.addMatcher(
            purchasePageApi.endpoints.setDefaultSavedCard.matchFulfilled,
            (state, action) => {
                // move new default to the top of array
                const newDefault = state.payMethods.find(
                    (method) =>
                        (method as SavedCardPayMethod)?.id ===
                        action.meta.arg.originalArgs.id,
                ) as SavedCardPayMethod
                state.payMethods = [
                    { ...newDefault, is_default: true },
                    ...state.payMethods
                        .filter(
                            (method) =>
                                (method as SavedCardPayMethod)?.id !==
                                action.meta.arg.originalArgs.id,
                        )
                        .map((method) => ({ ...method, is_default: false })),
                ]
                state.loading = false
            },
        )
        builder.addMatcher(
            isAnyOf(
                purchasePageApi.endpoints.setDefaultSavedCard.matchRejected,
                purchasePageApi.endpoints.deleteSavedCard.matchRejected,
            ),
            (state) => {
                state.error = i18n.errorProcessingRequest
            },
        )
        builder.addMatcher(
            purchasePageApi.endpoints.makeOneClickPurchase.matchFulfilled,
            (state) => {
                state.loading = false
                state.page = "success"
            },
        )
        builder.addMatcher(
            purchasePageApi.endpoints.makeOneClickPurchase.matchRejected,
            (state, action) => {
                state.loading = false
                // logic for handling cvv type error to allow user try multiple times
                const wasCvvProvided = Boolean(
                    action.meta.arg.originalArgs.cvv2,
                )
                if (isCustomRtkError(action.payload) && wasCvvProvided) {
                    if (Boolean(action.payload.data?.error)) {
                        state.error = i18n.transactionDeclinedError
                        state.page = "main"
                        return
                    }
                }
                state.page = "error"
            },
        )
        builder.addMatcher(
            purchasePageApi.endpoints.makePurchase.matchRejected,
            (state) => {
                state.loading = false
                state.error = i18n.errorProcessingRequest
            },
        )
        builder.addMatcher(
            isAnyOf(purchasePageApi.endpoints.getPurchaseData.matchRejected),
            (state) => {
                state.page = "error"
                state.loading = false
            },
        )
        builder.addMatcher(
            purchasePageApi.endpoints.makePurchase.matchFulfilled,
            (state, action) => {
                const { redirect_url } = action.payload
                state.loading = false

                if (redirect_url) {
                    state.redirect_url = redirect_url
                    return
                }
            },
        )
        // resetting state here since on dev with strict mode enabled, useEffect calling unmount randomly
        // order is important here
        builder.addMatcher(
            isAnyOf(purchasePageApi.endpoints.getPurchaseData.matchPending),
            () => {
                return initialState
            },
        )
        builder.addMatcher(
            isAnyOf(
                purchasePageApi.endpoints.getPurchaseData.matchPending,
                purchasePageApi.endpoints.makeOneClickPurchase.matchPending,
                purchasePageApi.endpoints.makePurchase.matchPending,
            ),
            (state) => {
                state.error = ""
                state.loading = true
            },
        )
        builder.addMatcher(
            isAnyOf(
                purchasePageApi.endpoints.setDefaultSavedCard.matchPending,
                purchasePageApi.endpoints.deleteSavedCard.matchPending,
            ),
            (state) => {
                state.error = ""
            },
        )
    },
})

export const { selectPaymethod, selectPaymentPlan, setPage } =
    purchasePageSlice.actions
export const {
    useDeleteSavedCardMutation,
    useGetPurchaseDataQuery,
    useSetDefaultSavedCardMutation,
    useMakeOneClickPurchaseMutation,
    useMakePurchaseMutation,
} = purchasePageApi

export default purchasePageSlice.reducer
