import type { ComponentPropsWithoutRef, SyntheticEvent } from "react"
import "./SpyRequest.scss"
import { gettext, interpolate } from "@multimediallc/web-utils"
import { Overlay } from "../../common"

interface SpyRequestProps extends ComponentPropsWithoutRef<"div"> {
    standardPrice: number
    fanPrice: number
    isFan: boolean
    requestCallback: () => void
    closeCallback: () => void
    joinCallback: () => void
}

const i18n = {
    fanClubNotice: (percentSaved: number): string => {
        return interpolate(
            gettext("Saving %(percentSaved)s% as a"),
            {
                percentSaved: percentSaved,
            },
            true,
        )
    },
    fanClubMember: "Fan Club member",
    spyTitle: "Spy on Private Show",
    spyDesc: "Spy on private show for ",
    spyPrice: (price: number): string => {
        return interpolate(
            gettext("%(price)s tokens/min"),
            {
                price: price,
            },
            true,
        )
    },
    basicUsers: (price: number): string => {
        return interpolate(
            gettext("(Basic users pay %(price)s tokens/min)"),
            {
                price: price,
            },
            true,
        )
    },
    fanClubJoin: (percentSaved: number): string => {
        return interpolate(
            gettext("save %(percentSaved)s% "),
            {
                percentSaved: percentSaved,
            },
            true,
        )
    },
    forSpying: "for spying",
    joinNow: "Join Now",
    cancel: "Cancel",
    request: "Request",
}

export function SpyRequest({
    standardPrice,
    fanPrice,
    isFan,
    requestCallback,
    closeCallback,
    joinCallback,
    ...props
}: SpyRequestProps) {
    const requestSpy = () => {
        requestCallback()
        closeCallback()
    }
    const absorb = (e: SyntheticEvent) => {
        e.stopPropagation()
    }
    let noDiscount = false
    if (standardPrice === fanPrice || fanPrice === undefined) {
        noDiscount = true
    }
    const percentSaved = Math.floor(
        ((standardPrice - fanPrice) / standardPrice) * 100,
    )
    const actualPrice = isFan && !noDiscount ? fanPrice : standardPrice
    const starIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <rect className="starBox" width="16" height="16" rx="3" />
            <path
                className="star"
                d="M12.775 6.67654C12.7121 6.48215 12.5397 6.34409 12.3357 6.3257L9.56492 6.07411L8.46926 3.50961C8.38847 3.32167 8.20449 3.20001 8.00006 3.20001C7.79564 3.20001 7.61165 3.32167 7.53086 3.51005L6.4352 6.07411L3.66394 6.3257C3.46032 6.34453 3.28834 6.48215 3.22513 6.67654C3.16192 6.87093 3.2203 7.08415 3.37433 7.21855L5.46875 9.05536L4.85115 11.7759C4.80596 11.9759 4.8836 12.1827 5.04957 12.3026C5.13878 12.3671 5.24315 12.3999 5.34841 12.3999C5.43916 12.3999 5.52917 12.3754 5.60996 12.3271L8.00006 10.8986L10.3893 12.3271C10.5641 12.4323 10.7845 12.4227 10.9501 12.3026C11.1162 12.1823 11.1937 11.9755 11.1485 11.7759L10.5309 9.05536L12.6254 7.21891C12.7794 7.08415 12.8382 6.8713 12.775 6.67654Z"
                fill="white"
            />
        </svg>
    )
    const actualPriceClass = isFan && !noDiscount ? "fanPrice" : "basicPrice"
    return (
        <div {...props}>
            <Overlay className="spyRequestOverlay" onClick={closeCallback}>
                <div className="spyRequestCard" onClick={absorb}>
                    {(!isFan || noDiscount) && <div className="buffer" />}
                    {isFan && !noDiscount && (
                        <div>
                            <svg
                                className="fanBanner"
                                xmlns="http://www.w3.org/2000/svg"
                                height="28"
                                fill="none"
                            >
                                <path
                                    className="bannerColor"
                                    d="M0 0H242C244.209 0 246 1.79086 246 4V24C246 26.2091 244.209 28 242 28H0V0Z"
                                    fill="#3B8443"
                                />
                            </svg>
                            <div className="fanClubNotice">
                                {starIcon}
                                <div>{i18n.fanClubNotice(percentSaved)}</div>
                                <div className="fanClubMember">
                                    {i18n.fanClubMember}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="defaultDesc">
                        <div className="spyTitle">{i18n.spyTitle}</div>
                        <div className="spyDesc">
                            <div className="spyPrice">
                                <div>{i18n.spyDesc}</div>
                                <div className={actualPriceClass}>
                                    {i18n.spyPrice(actualPrice)}
                                </div>
                            </div>
                            {isFan && !noDiscount && (
                                <div className="basicComparison">
                                    {i18n.basicUsers(standardPrice)}
                                </div>
                            )}
                        </div>
                        {!isFan && !noDiscount && (
                            <div className="fanClubJoin">
                                <div className="fanClubDiscountDesc">
                                    {starIcon}
                                    <div>{i18n.fanClubMember} </div>
                                    <div className="discount">
                                        {i18n.fanClubJoin(percentSaved)}
                                    </div>
                                    <div>{i18n.forSpying}</div>
                                </div>
                                <div
                                    className="joinButton"
                                    onClick={joinCallback}
                                >
                                    {i18n.joinNow}
                                </div>
                            </div>
                        )}
                        <svg
                            className="spyDivider"
                            xmlns="http://www.w3.org/2000/svg"
                            height="2"
                            fill="none"
                            width="100%"
                        >
                            <path d="M394 1H0" stroke="#7F7F7F" />
                        </svg>
                        <div className="spyButtonDiv">
                            <div className="spyCancel" onClick={closeCallback}>
                                {i18n.cancel}
                            </div>
                            <div className="spyConfirm" onClick={requestSpy}>
                                {i18n.request}
                            </div>
                        </div>
                    </div>
                </div>
            </Overlay>
        </div>
    )
}
