import { useEffect } from "react"
import { gettext } from "@multimediallc/web-utils"
import { Typography } from "../../atoms/Typography"
import type { TypographyProps } from "../../atoms/Typography/Typography"

interface TranslationWithHandlerProps extends TypographyProps {
    text: string
    id: string
    handler: () => void
}

export function TranslationWithHandler({
    text,
    id,
    handler,
    ...props
}: TranslationWithHandlerProps) {
    useEffect(() => {
        const clickableElement = document.getElementById(id)
        clickableElement?.style.setProperty("cursor", "pointer")
        clickableElement?.addEventListener("click", handler)
        return () => {
            document.getElementById(id)?.removeEventListener("click", handler)
        }
    }, [id, handler])

    return (
        <Typography
            dangerouslySetInnerHTML={{ __html: gettext(text) }}
            {...props}
        />
    )
}
