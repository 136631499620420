import { type ComponentPropsWithoutRef, useEffect, useState } from "react"
import "./ModalOption.scss"

interface ModalOptionProps extends ComponentPropsWithoutRef<"div"> {
    callback: () => void
    title: string
    description: string
    selected: boolean
    tooltip?: string
}

export function ModalOption({
    callback,
    title,
    description,
    selected,
    tooltip = "",
    ...props
}: ModalOptionProps) {
    const [isSelected, setIsSelected] = useState<boolean>(false)
    useEffect(() => {
        setIsSelected(selected)
    }, [selected])
    const clickHandler = () => {
        if (isSelected) {
            return
        }
        setIsSelected(true)
        callback()
    }
    const filled = selected ? (
        <path
            d="M9.10015 6.00039C9.10015 7.71247 7.71223 9.10039 6.00015 9.10039C4.28806 9.10039 2.90015 7.71247 2.90015 6.00039C2.90015 4.28831 4.28806 2.90039 6.00015 2.90039C7.71223 2.90039 9.10015 4.28831 9.10015 6.00039Z"
            fill="#306A91"
            stroke="#306A91"
        />
    ) : (
        ""
    )
    const iconDiv = (
        <svg
            className="optionIcon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 12 12"
        >
            <circle cx="6" cy="6" r="5.5" stroke="#306A91" />
            {filled}
        </svg>
    )
    const tooltipDiv =
        tooltip !== "" ? (
            <div className="optionToolTip">
                <svg
                    className="checkIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 15 14"
                    fill="none"
                    width="15"
                >
                    <path
                        d="M4.16102 10.3649C4.57937 10.7866 5.25831 10.7978 5.69047 10.3903L11.9178 4.53219C12.2135 4.26687 12.342 3.86248 12.2536 3.47554C12.1652 3.0886 11.8737 2.77983 11.4921 2.66874C11.1104 2.55766 10.6984 2.66169 10.4156 2.94057L4.96562 8.06719L3.64127 6.73282C3.36749 6.44749 2.96085 6.33174 2.57751 6.43004C2.19418 6.52834 1.89375 6.82539 1.7916 7.20712C1.68945 7.58885 1.80148 7.9959 2.08464 8.27194L4.16102 10.3649Z"
                        fill="#009900"
                    />
                </svg>
                <div className="optionTooltip">{tooltip}</div>
            </div>
        ) : null
    const className = ["modalOption", selected ? "selected" : ""].join(" ")
    return (
        <div {...props} className={className} onClick={clickHandler}>
            {iconDiv}
            <div className="summary">
                <div className="details">
                    <div className="optionTitle">{title}</div>
                    <div className="optionDescription">{description}</div>
                </div>
                {tooltipDiv}
            </div>
        </div>
    )
}
