import * as PurchaseIcons from "../common/atoms/Icons/Purchase"
import type { PurchaseMethodMappingType } from "./types"

export const SUGGEST_OTHER_PAYMETHODS_LINK =
    "https://www.surveymonkey.com/r/6WLZYGV"

export const PAYMETHOD_CREDITCARD = "creditcard"
export const PAYMETHOD_CREDITCARDEPOCH = "creditcardepoch"
export const PAYMETHOD_PAYPALEPOCH = "paypalepoch"
export const PAYMETHOD_CRYPTOPAYMENT = "cryptopayment"
export const PAYMETHOD_WIRE = "wire"
export const PAYMETHOD_PAYGARDEN = "paygarden"
export const PAYMETHOD_IDEAL = "Ideal"
export const PAYMETHOD_SOFORT = "Sofort"
export const PAYMETHOD_REFER_FRIEND = "refer_friend"
export const PAYMETHOD_PAYSAFECARD = "Paysafecard"
export const PAYMETHOD_SAFETYPAY = "safetypay"
export const PAYMETHOD_JCB = "jcb"
export const PAYMETHOD_DPMAX = "DPMax"
export const PAYMETHOD_SAVECARD = "savedcard"
export const PAYMETHOD_EPOCH_ONECLICK = "epochoneclick"
export const PAYMETHOD_PAYPAL_EPOCH_ONECLICK = "paypalepochoneclick"

export const CRYPTO_MIN_TOKENS = "250"
export const MAX_TOKENS_DISPLAY_CUTOFF = 50000
export const DISPLAY_CUTOFF_TEXT = "50000+"

export const genericDefaultPaymethodMapping = (
    label: string,
): PurchaseMethodMappingType => ({
    label,
    icons: [PurchaseIcons.Creditcardgeneric],
    iconStyles: {
        width: "40px",
        height: "19px",
    },
})

export const purchaseMethodsMappings: Record<
    string,
    PurchaseMethodMappingType
> = {
    [PAYMETHOD_CREDITCARD]: {
        label: "Credit or Debit Card",
        icons: [
            PurchaseIcons.Visa,
            PurchaseIcons.Mastercard,
            PurchaseIcons.Discover,
        ],
        iconStyles: {
            width: "28px",
            height: "19px",
        },
    },
    [PAYMETHOD_CREDITCARDEPOCH]: {
        label: "Epoch (Credit Card/JCB/Maestro/Diners Club)",
        icons: [PurchaseIcons.Epoch],
        iconStyles: {
            width: "40px",
            height: "19px",
        },
    },
    [PAYMETHOD_PAYPALEPOCH]: {
        label: "PayPal",
        icons: [PurchaseIcons.PayPal],
        iconStyles: {
            width: "28px",
            height: "19px",
            fill: "#fff",
        },
    },
    [PAYMETHOD_CRYPTOPAYMENT]: {
        label: "Cryptocurrency",
        icons: [
            PurchaseIcons.Bitcoin,
            PurchaseIcons.Ethereum,
            PurchaseIcons.Litecoin,
        ],
        iconStyles: {
            width: "19px",
            height: "19px",
        },
    },
    [PAYMETHOD_WIRE]: {
        label: "Wire Transfer",
        icons: [PurchaseIcons.Wire],
        iconStyles: {
            width: "28px",
            height: "19px",
        },
    },
    [PAYMETHOD_PAYGARDEN]: {
        label: "Pay with 70+ brand gift cards",
        icons: [PurchaseIcons.Paygarden],
        iconStyles: {
            width: "28px",
            height: "19px",
        },
    },
    [PAYMETHOD_IDEAL]: {
        label: "iDeal",
        icons: [PurchaseIcons.IDeal],
        iconStyles: {
            width: "28px",
            height: "19px",
        },
    },
    [PAYMETHOD_SOFORT]: {
        label: "Sofort",
        icons: [PurchaseIcons.Sofort],
        iconStyles: {
            width: "40px",
            height: "19px",
        },
    },
    [PAYMETHOD_PAYSAFECARD]: {
        label: "Paysafecard",
        icons: [PurchaseIcons.PaySafeCard],
        iconStyles: {
            width: "60px",
            height: "19px",
        },
    },
    [PAYMETHOD_SAFETYPAY]: {
        label: "SafetyPay",
        icons: [PurchaseIcons.SafetyPay],
        iconStyles: {
            width: "60px",
            height: "19px",
        },
    },
    [PAYMETHOD_JCB]: {
        label: "JCB",
        icons: [PurchaseIcons.Jcb],
        iconStyles: {
            width: "28px",
            height: "19px",
        },
    },
    [PAYMETHOD_REFER_FRIEND]: {
        label: "Bring a friend and earn tokens!",
        icons: [PurchaseIcons.Bringfriend],
        iconStyles: {
            width: "19px",
            height: "19px",
        },
    },
    [PAYMETHOD_DPMAX]: {
        label: "Pay By Bank",
        icons: [PurchaseIcons.Wire],
        iconStyles: {
            width: "40px",
            height: "19px",
        },
    },
}
