import { useState } from "react"
import type { ComponentPropsWithoutRef } from "react"
import "./PurchaseRow.scss"
import { gettext } from "@multimediallc/web-utils"
import { useOutsideClick } from "../../../hooks/useOutsideClick"
import { Weights } from "../../../interfaces/sizing"
import { useSetDefaultSavedCardMutation } from "../../../store/purchasePage/purchasePageSlice"
import { mergeClasses } from "../../../utils/css"
import { Button, ButtonColor, Typography } from "../../common"
import { Checkmark, Chevron } from "../../common/atoms/Icons/Others"
import * as PurchaseIcons from "../../common/atoms/Icons/Purchase"
import { InputRadio } from "../../common/atoms/InputRadio"
import { List } from "../../common/atoms/List"
import { PAYMETHOD_SAVECARD } from "../constants"
import { getPayMethodMappings } from "../utils"
import type { TokenPackage } from "../types"

export const getIconForCardType = (cardType: string): React.ReactElement => {
    const iconStyles = {
        width: 70,
        height: 48,
    }
    switch (cardType) {
        case "visa":
            return <PurchaseIcons.Visa {...iconStyles} />
        case "master":
            return <PurchaseIcons.Mastercard {...iconStyles} />
        case "discover":
            return <PurchaseIcons.Discover {...iconStyles} />
        default:
            return <PurchaseIcons.Creditcardgeneric {...iconStyles} />
    }
}

const i18n = {
    upgradeToSupporter: "Upgrade to supporter & get 200 tokens FREE!",
    monthly: "monthly",
    privateMessaging: "Private Messaging",
    cleanAdFreeInterface: "Clean, Ad-Free Interface",
    anonymousTipping: "Anonymous Tipping",
    customChatFontAndColorOptions: "Custom Chat Font and Color Options",
    previewRooms: "Preview Rooms",
    delete: "Delete",
    setAsDefault: "Set as default",
    endingIn: "ending in",
    expires: "Expires:",
}
interface PurchaseMethodRowProps extends ComponentPropsWithoutRef<"input"> {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    purchaseMethod: string
    checked: boolean
    savedCardProps?: SavedCardProps
    id: string
}

interface PurchaseTokenPlanRowProps extends ComponentPropsWithoutRef<"div"> {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    tokenPackage: TokenPackage
    checked: boolean
}
interface SavedCardProps {
    last4: string
    cardType: string
    expiresAt: string
    id: string
    handleOpenCardDeleteModal?: () => void
}

const benefitsArray = [
    gettext(i18n.privateMessaging),
    gettext(i18n.cleanAdFreeInterface),
    gettext(i18n.anonymousTipping),
    gettext(i18n.customChatFontAndColorOptions),
    gettext(i18n.previewRooms),
].map((benefit) => ({
    id: benefit,
    label: <BenefitLabel benefit={benefit} />,
}))

function CustomPurchaseRowLabel({
    purchaseMethod,
    savedCardProps,
}: {
    purchaseMethod: string
    savedCardProps?: SavedCardProps
}) {
    if (purchaseMethod === "savedcard" && savedCardProps) {
        return <SavedCardLabel {...savedCardProps} />
    }

    const { label, icons, iconStyles } = getPayMethodMappings(purchaseMethod)
    return (
        <div className="purchase-row-label">
            <span>{label}</span>
            <div className="purchase-row-icons">
                {icons.map((Icon, index) => (
                    <Icon key={index} {...iconStyles} />
                ))}
            </div>
        </div>
    )
}

export function SavedCardLabel({
    last4,
    cardType,
    expiresAt,
    id,
    handleOpenCardDeleteModal,
}: SavedCardProps) {
    const [showDropdown, setShowDropdown] = useState(false)
    const [setDefaultSavedCard] = useSetDefaultSavedCardMutation()
    const ref = useOutsideClick(() => setShowDropdown(false))
    return (
        <div className="purchase-row-label">
            <div className="cardDetailsWrapper">
                <div className="cardIcon"> {getIconForCardType(cardType)}</div>
                <div className="cardDetails">
                    <Typography size="smpx" weight={Weights.bold}>
                        {cardType.charAt(0).toUpperCase() + cardType.slice(1)}
                        <Typography
                            size="smpx"
                            component="span"
                        >{` ending in ${last4}`}</Typography>
                    </Typography>
                    <Typography size="xspx">{`Expires: ${expiresAt}`}</Typography>
                </div>
            </div>
            {handleOpenCardDeleteModal && (
                <div className="savedCard__actions">
                    <Button
                        color={ButtonColor.Link}
                        icon={<PurchaseIcons.ThreeDots height={23} />}
                        onClick={() => setShowDropdown(!showDropdown)}
                    />
                    {showDropdown && (
                        <div className="savedCard__dropdown" ref={ref}>
                            <Button
                                color={ButtonColor.Link}
                                className="savedCard__dropdown__button"
                                text="Set as default"
                                onClick={async () => {
                                    await setDefaultSavedCard({ id })
                                    setShowDropdown(false)
                                }}
                            />
                            <Button
                                color={ButtonColor.Link}
                                className="savedCard__dropdown__button"
                                text="Delete"
                                onClick={() => {
                                    setShowDropdown(false)
                                    handleOpenCardDeleteModal()
                                }}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

function CustomPurchaseTokenOptionLabel({
    tokenPackage,
}: {
    tokenPackage: TokenPackage
}) {
    const { tokens, percent_bonus, cost, product_id } = tokenPackage

    const isSupporterPlan = product_id.endsWith("3995")

    if (isSupporterPlan) {
        return <SupporterPlanLabel tokenPackage={tokenPackage} />
    }
    return (
        <div className="purchase-row-label">
            <Typography
                component="span"
                size="mdpx"
            >{`${tokens} tokens`}</Typography>
            <div className="purchase-row-label__right">
                <Typography
                    className="purchase-row-label__cost"
                    size="smpx"
                    weight={Weights.bold}
                >{`$${cost}`}</Typography>
                {percent_bonus > 0 && (
                    <Typography
                        size="xspx"
                        className="purchase-row-label__bonus"
                        weight={Weights.bold}
                    >{`${percent_bonus}% Bonus`}</Typography>
                )}
            </div>
        </div>
    )
}

function BenefitLabel({ benefit }: { benefit: string }) {
    return (
        <div className="benefit-label">
            <Checkmark className="benefit-checkmark" />
            <Typography size="xspx">{benefit}</Typography>
        </div>
    )
}

function SupporterPlanLabel({ tokenPackage }: { tokenPackage: TokenPackage }) {
    const { cost } = tokenPackage
    return (
        <div className="purchase-row-label supporter-row">
            <div className="purchase-row-label__supporter">
                <Typography size="xspx">{i18n.upgradeToSupporter}</Typography>
            </div>
            <div className="purchase-row-label__right">
                <Typography
                    className="purchase-row-label__cost"
                    size="smpx"
                    weight={Weights.bold}
                >{`$${cost}`}</Typography>
                <Typography size="xspx">{i18n.monthly}</Typography>
            </div>
        </div>
    )
}

export function PurchaseMethodRow({
    purchaseMethod,
    onChange,
    checked,
    savedCardProps,
    id,
    ...other
}: PurchaseMethodRowProps) {
    const isSavedCard = purchaseMethod === PAYMETHOD_SAVECARD
    return (
        <div
            className={mergeClasses(
                "purchase-row",
                isSavedCard ? "savedcard" : "",
            )}
        >
            <InputRadio
                {...other}
                id={id}
                name={purchaseMethod}
                checked={checked}
                onChange={onChange}
                label={
                    <CustomPurchaseRowLabel
                        purchaseMethod={purchaseMethod}
                        savedCardProps={savedCardProps}
                    />
                }
            />
        </div>
    )
}

export function PurchaseTokenPlanRow({
    tokenPackage,
    onChange,
    checked,
    ...other
}: PurchaseTokenPlanRowProps) {
    const [benefitsClicked, setBenefitsClicked] = useState(false)

    const { product_id } = tokenPackage
    const isSupporterPlan = product_id.endsWith("3995")
    return (
        <div
            className={mergeClasses(
                "purchase-row",
                isSupporterPlan ? "supporter-row" : "",
            )}
        >
            <InputRadio
                {...other}
                id={product_id}
                name={product_id}
                checked={checked}
                onChange={onChange}
                label={
                    <CustomPurchaseTokenOptionLabel
                        tokenPackage={tokenPackage}
                    />
                }
            />
            {isSupporterPlan ? (
                <div
                    className={mergeClasses(
                        "supporter-benefits",
                        isSupporterPlan && checked ? "selected" : "",
                    )}
                >
                    <div className="benefits-button-wrapper">
                        <Button
                            className="benefits-button"
                            color={ButtonColor.Link}
                            onClick={() => setBenefitsClicked(!benefitsClicked)}
                            text="Benefits"
                            icon={
                                <Chevron
                                    className={mergeClasses(
                                        "benefits-icon",
                                        benefitsClicked ? "open" : "",
                                    )}
                                    color="#F47321"
                                />
                            }
                        />
                    </div>
                    {benefitsClicked && (
                        <List
                            className="purchase-row__benefits"
                            options={benefitsArray}
                        />
                    )}
                </div>
            ) : null}
        </div>
    )
}
