import type { ComponentPropsWithoutRef, KeyboardEvent, MouseEvent } from "react"
import { gettext, interpolate } from "@multimediallc/web-utils"
import { renderToStaticMarkup } from "react-dom/server"
import "./TagPagination.scss"
import { Chevron, Line } from "../../../common/atoms/Icons/Others"

const i18n = {
    of: (currentPage: string, maxPage: string): string => {
        return interpolate(
            gettext(
                "%(currentPage)s <div class='TagPagination__paging-text'>of</div> %(maxPage)s ",
            ),
            { currentPage: currentPage, maxPage: maxPage },
            true,
        )
    },
    next: "next",
}

interface TagPaginationProps extends ComponentPropsWithoutRef<"div"> {
    isDisabled: boolean
    currentPage: number
    maxPage: number
    onNextPageClick: (e: MouseEvent | KeyboardEvent) => void
    onPrevPageClick: (e: MouseEvent | KeyboardEvent) => void
}

export function TagPagination({
    isDisabled,
    currentPage,
    maxPage,
    onNextPageClick,
    onPrevPageClick,
    ...props
}: TagPaginationProps) {
    const createNumberText = (pageNum: number) => {
        return <div className="TagPagination__paging-text">{pageNum}</div>
    }

    const createPageNumberDisplayMarkUp = () => {
        return {
            __html: i18n.of(
                renderToStaticMarkup(createNumberText(currentPage)),
                renderToStaticMarkup(createNumberText(maxPage)),
            ),
        }
    }

    const handlePrevKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            onPrevPageClick(event)
        }
    }

    const handleNextKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            onNextPageClick(event)
        }
    }

    return (
        <div
            {...props}
            className={`TagPagination${
                isDisabled ? " TagPagination--disabled" : ""
            }`}
        >
            <div className="TagPagination__spacer" />
            <div
                className="TagPagination__left-arrow"
                tabIndex={0}
                onClick={onPrevPageClick}
                onKeyDown={handlePrevKeyPress}
            >
                <Chevron className="TagPagination__arrow-icon" />
                <Line className="TagPagination__chevron-underline" />
            </div>

            <div
                className="TagPagination__paging-display"
                dangerouslySetInnerHTML={createPageNumberDisplayMarkUp()}
            />
            <div
                className={`TagPagination__page-button${currentPage > 9 ? " extra-space" : ""}`}
                onClick={onNextPageClick}
                onKeyDown={handleNextKeyPress}
                title={i18n.next}
            >
                <div
                    className="TagPagination__paging-text TagPagination__paging-text--next"
                    tabIndex={0}
                >
                    {i18n.next}
                </div>
                <Chevron className="TagPagination__arrow-icon TagPagination__arrow-icon--next" />
                <Line className="TagPagination__chevron-underline" />
            </div>
            <div className="TagPagination__spacer" />
        </div>
    )
}
