import { OneClickFlowNoLink as OneClickFlow } from "../components/chat/OneClickFlow"
import {
    CloseButton,
    RemoveFilterIcon,
    Search,
} from "../components/common/atoms/Icons/Others/index"
import CancelConfirmButtonPair from "../components/common/molecules/CancelConfirmButtonPair"
import { FlagIcon } from "../components/common/molecules/FlagIcon"
import { ContestStats } from "../components/contest/ContestStats"
import { MembershipDurationContainer } from "../components/memberships/MembershipDurationContainer"
import { NPSSentimentSurvey } from "../components/memberships/NPSSentimentSurvey"
import { PurchasePage } from "../components/purchase_page/PurchasePage"
import { AbuseLinkSection } from "../components/room/AbuseLinkSection"
import { MenuLabel } from "../components/room/MenuLabel"
import { OptInFlagDisplayHelpText } from "../components/room/OptInFlagDisplayHelpText"
import { PremiumPrivateRequest } from "../components/room/PremiumPrivateRequest"
import { PrivateCancelEarlyOverlay } from "../components/room/PrivateCancelEarlyOverlay"
import { RoomFlagNotification } from "../components/room/RoomFlagNotification"
import { RoomSubject } from "../components/room/RoomSubject"
import { getApprovedTagsFromTitle } from "../components/room/RoomSubjectSpan/api"
import { UserList } from "../components/room/roomUserList/UserList"
import { UserNote } from "../components/room/roomUserList/UserNote"
import { SendButton } from "../components/room/SendButton"
import { SpyRequest } from "../components/room/SpyRequest"
import { VideoOverlay } from "../components/room/Video"
import { HomepageFiltersIcon } from "../components/roomlist/filters/HomepageFiltersIcon"
import { TagPagination } from "../components/roomlist/filters/TagPagination"
import { LanguageFilterSection } from "../components/roomlist/LanguageFilterSection"
import { makeAPIRegistry, makeComponentRegistry } from "../utils/registry"

makeComponentRegistry({
    OneClickFlow,
    ContestStats,
    UserNote,
    SendButton,
    MembershipDurationContainer,
    MenuLabel,
    UserList,
    VideoOverlay,
    RoomFlagNotification,
    OptInFlagDisplayHelpText,
    NPSSentimentSurvey,
    TagPagination,
    AbuseLinkSection,
    RoomSubject,
    PrivateCancelEarlyOverlay,
    FlagIcon,
    HomepageFiltersIcon,
    LanguageFilterSection,
    SpyRequest,
    RemoveFilterIcon,
    CloseButton,
    Search,
    PurchasePage,
    CancelConfirmButtonPair,
    PremiumPrivateRequest,
})

makeAPIRegistry({
    getApprovedTagsFromTitle,
})
