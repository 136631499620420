import { Gender } from "@multimediallc/gender-utils"
import { gettext } from "@multimediallc/web-utils"
import { Couple, Female, Male, Trans } from ".."
import { error } from "../../../../../../utils/debug"

type GenderIconProps = {
    gender: Gender
}

export default function GenderIcon({ gender }: GenderIconProps) {
    switch (gender) {
        case Gender.Male:
            return <Male title={gettext("Male")} />
        case Gender.Female:
            return <Female title={gettext("Female")} />
        case Gender.Trans:
            return <Trans title={gettext("Trans")} />
        case Gender.Couple:
            return <Couple title={gettext("Couple")} />
        default:
            error("Could not set menu gender icon. Defaulting to male.")
            return <Male title={gettext("Male")} />
    }
}
