import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgMale = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 12 12"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="url(#male_svg__a)"
            d="M11.228 11.79H.907a.544.544 0 0 1-.574-.51V9.356c0-.281.27-.311.573-.51l5.16-2.644 5.16 2.652c.338.21.574.228.574.51v1.916a.543.543 0 0 1-.574.51"
        />
        <path
            fill="url(#male_svg__b)"
            d="M6.964 11.374V7.106l4.159 1.819q.14.083.258.139c.21.105.21.116.21.176v1.849a.337.337 0 0 1-.375.288H6.964zm-6.057 0a.337.337 0 0 1-.375-.289V9.236c0-.06.02-.082.222-.18q.13-.063.255-.138L5.242 7.06v4.313z"
        />
        <path
            fill="url(#male_svg__c)"
            d="M6.964 11.374V7.106l4.159 1.819q.14.083.258.139c.21.105.21.116.21.176v1.849a.337.337 0 0 1-.375.288H6.964zm-6.057 0a.337.337 0 0 1-.375-.289V9.236c0-.06.02-.082.222-.18q.13-.063.255-.138L5.242 7.06v4.313z"
        />
        <path fill="url(#male_svg__d)" d="M5.209 8.587H6.93v3.203H5.209z" />
        <path
            fill="url(#male_svg__e)"
            d="m7.553 8.565-.225.289H4.804l-.221-.289V5.494h2.97z"
        />
        <path
            fill="url(#male_svg__f)"
            d="M8.693 2.981a.32.32 0 0 0-.263.154v-.832c0-.428-.251-2.13-1.987-2.13h-.86c-1.5 0-1.923 1.702-1.923 2.13v.78a.3.3 0 0 0-.217-.102.375.375 0 0 0-.33.375l.093.882a.375.375 0 0 0 .33.375.3.3 0 0 0 .214-.094v.892c0 .428 1.463 1.77 1.841 1.77h.852c.375 0 1.848-1.361 1.848-1.788v-.837a.32.32 0 0 0 .263.154.375.375 0 0 0 .33-.375l.139-.979a.375.375 0 0 0-.33-.375"
        />
        <path
            fill="url(#male_svg__g)"
            d="M8.693 2.981a.32.32 0 0 0-.263.154v-.832c0-.428-.251-2.13-1.987-2.13h-.86c-1.5 0-1.923 1.702-1.923 2.13v.78a.3.3 0 0 0-.217-.102.375.375 0 0 0-.33.375l.093.882a.375.375 0 0 0 .33.375.3.3 0 0 0 .214-.094v.892c0 .428 1.463 1.77 1.841 1.77h.852c.375 0 1.848-1.361 1.848-1.788v-.837a.32.32 0 0 0 .263.154.375.375 0 0 0 .33-.375l.139-.979a.375.375 0 0 0-.33-.375"
        />
        <path
            fill="#4C3D3D"
            d="M8.43 2.303c0-.428-.113-2.13-1.969-2.13h-.926c-1.616 0-1.875 1.702-1.875 2.13 0 0-.158 1.875.259 2.25l.15-1.65c0-.428.375-1.125 1.053-.93.628.149 1.282.149 1.91 0 .494-.143.88.483.88.91l.203 1.68c.36-.34.315-2.26.315-2.26"
        />
        <rect
            width={2.75}
            height={1.45}
            x={4.65}
            y={0.25}
            fill="url(#male_svg__h)"
            opacity={0.5}
            rx={0.725}
            style={{
                mixBlendMode: "screen",
            }}
        />
        <defs>
            <radialGradient
                id="male_svg__b"
                cx={0}
                cy={0}
                r={1}
                gradientTransform="translate(6.067 9.217)scale(4.19625)"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#7FC8E7" />
                <stop offset={1} stopColor="#6DCFF6" />
            </radialGradient>
            <radialGradient
                id="male_svg__f"
                cx={0}
                cy={0}
                r={1}
                gradientTransform="matrix(2.65665 0 0 3.91035 6.035 3.674)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.71} stopColor="#B49167" />
                <stop offset={0.82} stopColor="#B08C60" />
                <stop offset={0.96} stopColor="#A67E4E" />
                <stop offset={0.99} stopColor="#A37A49" />
            </radialGradient>
            <radialGradient
                id="male_svg__g"
                cx={0}
                cy={0}
                r={1}
                gradientTransform="matrix(2.65665 0 0 3.91035 6.035 3.674)"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#EDBE89" />
                <stop offset={0.569} stopColor="#EDBE89" />
                <stop offset={0.952} stopColor="#A67E4E" />
                <stop offset={1} stopColor="#A37A49" />
            </radialGradient>
            <radialGradient
                id="male_svg__h"
                cx={0}
                cy={0}
                r={1}
                gradientTransform="matrix(0 .5 -1.42187 0 6 1)"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#C4C4C4" />
                <stop offset={1} stopColor="#C4C4C4" stopOpacity={0} />
            </radialGradient>
            <linearGradient
                id="male_svg__a"
                x1={6.003}
                x2={6.003}
                y1={6.123}
                y2={11.946}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#AAD1F6" />
                <stop offset={0.486} stopColor="#82AAD7" />
                <stop offset={1} stopColor="#57789D" />
            </linearGradient>
            <linearGradient
                id="male_svg__c"
                x1={6}
                x2={6}
                y1={7}
                y2={11.5}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.028} stopColor="#57789D" />
                <stop offset={0.486} stopColor="#82AAD7" />
                <stop offset={1} stopColor="#AAD1F6" />
            </linearGradient>
            <linearGradient
                id="male_svg__d"
                x1={6.067}
                x2={6.067}
                y1={11.79}
                y2={8.587}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#9D9FA2" />
                <stop offset={0.49} stopColor="#fff" />
                <stop offset={0.68} stopColor="#FDFDFD" />
                <stop offset={0.77} stopColor="#F5F5F6" />
                <stop offset={0.85} stopColor="#E7E8E9" />
                <stop offset={0.92} stopColor="#D4D5D7" />
                <stop offset={0.97} stopColor="#BCBEC0" />
                <stop offset={1} stopColor="#AEB0B3" />
            </linearGradient>
            <linearGradient
                id="male_svg__e"
                x1={4.583}
                x2={7.553}
                y1={7.174}
                y2={7.174}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#A57C4B" />
                <stop offset={0.07} stopColor="#BE9461" />
                <stop offset={0.14} stopColor="#D2A773" />
                <stop offset={0.22} stopColor="#E0B480" />
                <stop offset={0.32} stopColor="#E9BC87" />
                <stop offset={0.49} stopColor="#EBBE89" />
                <stop offset={0.68} stopColor="#E9BC87" />
                <stop offset={0.78} stopColor="#E1B480" />
                <stop offset={0.86} stopColor="#D3A874" />
                <stop offset={0.93} stopColor="#C09663" />
                <stop offset={0.99} stopColor="#A87F4D" />
                <stop offset={1} stopColor="#A37A49" />
            </linearGradient>
        </defs>
    </svg>
)
const ForwardRef = forwardRef(SvgMale)
const Memo = memo(ForwardRef)
export default Memo
