import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgCouple = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 12 12"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="url(#couple_svg__a)"
            d="M11.483 7.89H4.577a.364.364 0 0 1-.384-.342V6.261c0-.188.181-.208.384-.341L8.03 4.15l3.453 1.775c.226.14.384.153.384.341v1.282a.364.364 0 0 1-.384.342"
        />
        <path
            fill="url(#couple_svg__b)"
            d="M8.63 7.611V4.755l2.783 1.217q.094.056.173.093c.14.07.14.078.14.118V7.42a.226.226 0 0 1-.25.194H8.63zm-4.053 0a.226.226 0 0 1-.25-.193V6.18c0-.04.012-.056.147-.12a2 2 0 0 0 .171-.094l2.833-1.242v2.886z"
        />
        <path
            fill="url(#couple_svg__c)"
            d="M8.63 7.611V4.755l2.783 1.217q.093.056.173.093c.14.07.14.078.14.118V7.42a.226.226 0 0 1-.25.194H8.63zm-4.053 0a.226.226 0 0 1-.25-.193V6.18c0-.04.012-.056.147-.12a2 2 0 0 0 .17-.094l2.834-1.242v2.886z"
        />
        <path fill="url(#couple_svg__d)" d="M7.456 5.747h1.151V7.89H7.456z" />
        <path
            fill="url(#couple_svg__e)"
            d="m9.024 5.731-.15.194h-1.69l-.148-.194V3.676h1.988z"
        />
        <path
            fill="url(#couple_svg__f)"
            d="M9.787 1.995a.21.21 0 0 0-.176.103V1.54c0-.286-.168-1.426-1.33-1.426h-.575c-1.003 0-1.287 1.14-1.287 1.426v.522a.2.2 0 0 0-.145-.068.25.25 0 0 0-.221.25l.062.59a.25.25 0 0 0 .221.252.2.2 0 0 0 .143-.063v.597c0 .286.979 1.184 1.232 1.184h.57c.251 0 1.237-.91 1.237-1.196v-.56a.21.21 0 0 0 .176.103.25.25 0 0 0 .22-.251l.094-.655a.25.25 0 0 0-.221-.251"
        />
        <path
            fill="url(#couple_svg__g)"
            d="M9.787 1.995a.21.21 0 0 0-.176.103V1.54c0-.286-.168-1.426-1.33-1.426h-.575c-1.003 0-1.287 1.14-1.287 1.426v.522a.2.2 0 0 0-.145-.068.25.25 0 0 0-.221.25l.062.59a.25.25 0 0 0 .221.252.2.2 0 0 0 .143-.063v.597c0 .286.979 1.185 1.232 1.185h.57c.251 0 1.237-.911 1.237-1.197v-.56a.21.21 0 0 0 .176.103.25.25 0 0 0 .22-.251l.094-.655a.25.25 0 0 0-.221-.251"
        />
        <path
            fill="#4C3D3D"
            d="M9.611 1.54c0-.285-.075-1.425-1.317-1.425h-.62c-1.082 0-1.255 1.14-1.255 1.426 0 0-.105 1.254.173 1.505l.1-1.104c0-.286.252-.753.706-.622.42.1.857.1 1.277 0 .331-.095.59.324.59.61L9.4 3.054c.241-.228.211-1.513.211-1.513"
        />
        <rect
            width={1.84}
            height={0.97}
            x={7.082}
            y={0.167}
            fill="url(#couple_svg__h)"
            opacity={0.5}
            rx={0.485}
            style={{
                mixBlendMode: "screen",
            }}
        />
        <mask
            id="couple_svg__i"
            width={9}
            height={9}
            x={0}
            y={3}
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
        >
            <path fill="#fff" d="M0 3.97h8.03V12H0z" />
        </mask>
        <g mask="url(#couple_svg__i)">
            <path
                fill="url(#couple_svg__j)"
                d="M7.24 11.817H.85a.34.34 0 0 1-.353-.316v-.921c0-.174.168-.194.354-.316l3.194-1.905 3.195 1.907c.208.13.353.143.353.316v.921a.34.34 0 0 1-.353.314"
            />
            <path
                fill="url(#couple_svg__k)"
                d="M3.533 8.258h1.064v1.576H3.533z"
            />
            <path
                fill="url(#couple_svg__l)"
                d="M4.145 4.098c1.33 0 2.116 1.91 1.983 4.394-1.581.113-4.123.09-4.123.09s-.344-4.484 2.14-4.484"
            />
            <path
                fill="#4B201C"
                fillOpacity={0.34}
                d="M4.045 8.758c.693 0 1.255-.976 1.255-2.18s-.562-2.182-1.255-2.182-1.254.977-1.254 2.181.561 2.18 1.254 2.18"
            />
            <path
                fill="url(#couple_svg__m)"
                d="M4.11 8.758c.693 0 1.255-.976 1.255-2.18S4.803 4.395 4.11 4.395s-1.254.977-1.254 2.181.561 2.18 1.254 2.18"
            />
            <path
                fill="url(#couple_svg__n)"
                d="M4.148 4.098c-1.255 0-1.79 1.164-2.008 2.306l.713.226a3.9 3.9 0 0 1 1.292-.733c.753-.25.65-.798.65-.798a1.18 1.18 0 0 1 .53.916c.042.366.18.115.502-.213-.316-1.044-.904-1.704-1.68-1.704"
            />
            <ellipse
                cx={4.032}
                cy={6.981}
                fill="url(#couple_svg__o)"
                rx={1.589}
                ry={2.677}
            />
            <path
                fill="#E1C3C2"
                d="M4.6 11.558V8.916l2.574 1.393q.088.051.16.085c.131.065.131.073.131.11v.879a.21.21 0 0 1-.225.178H4.6zm-3.75 0a.21.21 0 0 1-.225-.178v-.878c0-.038 0-.05.138-.113q.081-.039.158-.085L3.543 8.89v2.667z"
            />
            <path
                fill="url(#couple_svg__p)"
                d="M4.6 11.558V8.916l2.574 1.393q.088.051.16.085c.131.065.131.073.131.11v.879a.21.21 0 0 1-.225.178H4.6zm-3.75 0a.21.21 0 0 1-.225-.178v-.878c0-.038 0-.05.138-.113q.081-.039.158-.085L3.543 8.89v2.667z"
            />
            <path
                fill="#DA1C5C"
                d="M3.346 8.932a.2.2 0 1 0-.2-.347.2.2 0 0 0 .2.347"
            />
            <path
                fill="#DA1C5C"
                d="M3.544 9.279a.2.2 0 1 0-.2-.348.2.2 0 0 0 .2.348M4.799 9.279a.2.2 0 1 0-.201-.348.2.2 0 0 0 .2.348"
            />
            <path
                fill="#DA1C5C"
                d="M4.997 8.935a.2.2 0 1 0-.201-.348.2.2 0 0 0 .2.348"
            />
            <path
                fill="url(#couple_svg__q)"
                d="M3.533 9.834h1.064v1.983H3.533z"
            />
        </g>
        <defs>
            <linearGradient
                id="couple_svg__a"
                x1={7.987}
                x2={7.987}
                y1={4.098}
                y2={7.994}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#AAD1F6" />
                <stop offset={0.486} stopColor="#82AAD7" />
                <stop offset={1} stopColor="#57789D" />
            </linearGradient>
            <linearGradient
                id="couple_svg__c"
                x1={7.985}
                x2={7.985}
                y1={4.684}
                y2={7.695}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.028} stopColor="#57789D" />
                <stop offset={0.486} stopColor="#82AAD7" />
                <stop offset={1} stopColor="#AAD1F6" />
            </linearGradient>
            <linearGradient
                id="couple_svg__d"
                x1={8.03}
                x2={8.03}
                y1={7.89}
                y2={5.747}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#9D9FA2" />
                <stop offset={0.49} stopColor="#fff" />
                <stop offset={0.68} stopColor="#FDFDFD" />
                <stop offset={0.77} stopColor="#F5F5F6" />
                <stop offset={0.85} stopColor="#E7E8E9" />
                <stop offset={0.92} stopColor="#D4D5D7" />
                <stop offset={0.97} stopColor="#BCBEC0" />
                <stop offset={1} stopColor="#AEB0B3" />
            </linearGradient>
            <linearGradient
                id="couple_svg__e"
                x1={7.036}
                x2={9.024}
                y1={4.8}
                y2={4.8}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#A57C4B" />
                <stop offset={0.07} stopColor="#BE9461" />
                <stop offset={0.14} stopColor="#D2A773" />
                <stop offset={0.22} stopColor="#E0B480" />
                <stop offset={0.32} stopColor="#E9BC87" />
                <stop offset={0.49} stopColor="#EBBE89" />
                <stop offset={0.68} stopColor="#E9BC87" />
                <stop offset={0.78} stopColor="#E1B480" />
                <stop offset={0.86} stopColor="#D3A874" />
                <stop offset={0.93} stopColor="#C09663" />
                <stop offset={0.99} stopColor="#A87F4D" />
                <stop offset={1} stopColor="#A37A49" />
            </linearGradient>
            <linearGradient
                id="couple_svg__j"
                x1={4.045}
                x2={4.045}
                y1={8.359}
                y2={11.817}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#ECCBCF" />
                <stop offset={1} stopColor="#AF3241" />
            </linearGradient>
            <linearGradient
                id="couple_svg__k"
                x1={3.533}
                x2={4.6}
                y1={9.046}
                y2={9.046}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#A57C4B" />
                <stop offset={0.07} stopColor="#BE9461" />
                <stop offset={0.14} stopColor="#D2A773" />
                <stop offset={0.22} stopColor="#E0B480" />
                <stop offset={0.32} stopColor="#E9BC87" />
                <stop offset={0.49} stopColor="#EBBE89" />
                <stop offset={0.68} stopColor="#E9BC87" />
                <stop offset={0.78} stopColor="#E1B480" />
                <stop offset={0.86} stopColor="#D3A874" />
                <stop offset={0.93} stopColor="#C09663" />
                <stop offset={0.99} stopColor="#A87F4D" />
                <stop offset={1} stopColor="#A37A49" />
            </linearGradient>
            <linearGradient
                id="couple_svg__l"
                x1={4.068}
                x2={4.068}
                y1={4.098}
                y2={8.582}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.56} stopColor="#6E3B35" />
                <stop offset={0.72} stopColor="#6A3430" />
                <stop offset={0.95} stopColor="#5D2221" />
                <stop offset={1} stopColor="#5A1D1D" />
            </linearGradient>
            <linearGradient
                id="couple_svg__n"
                x1={3.992}
                x2={3.992}
                y1={4.098}
                y2={6.63}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#CF8F5E" />
                <stop offset={0.04} stopColor="#C6875A" />
                <stop offset={0.2} stopColor="#A66C4D" />
                <stop offset={0.37} stopColor="#8D5642" />
                <stop offset={0.55} stopColor="#7C473B" />
                <stop offset={0.73} stopColor="#713E36" />
                <stop offset={0.95} stopColor="#6E3B35" />
            </linearGradient>
            <linearGradient
                id="couple_svg__p"
                x1={4.045}
                x2={4.045}
                y1={8.891}
                y2={11.562}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#AF3241" />
                <stop offset={1} stopColor="#ECCBCF" />
            </linearGradient>
            <linearGradient
                id="couple_svg__q"
                x1={4.068}
                x2={4.068}
                y1={11.817}
                y2={9.834}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#9D9FA2" />
                <stop offset={0.49} stopColor="#fff" />
                <stop offset={0.68} stopColor="#FDFDFD" />
                <stop offset={0.77} stopColor="#F5F5F6" />
                <stop offset={0.85} stopColor="#E7E8E9" />
                <stop offset={0.92} stopColor="#D4D5D7" />
                <stop offset={0.97} stopColor="#BCBEC0" />
                <stop offset={1} stopColor="#AEB0B3" />
            </linearGradient>
            <radialGradient
                id="couple_svg__b"
                cx={0}
                cy={0}
                r={1}
                gradientTransform="translate(8.03 6.168)scale(2.80802)"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#7FC8E7" />
                <stop offset={1} stopColor="#6DCFF6" />
            </radialGradient>
            <radialGradient
                id="couple_svg__f"
                cx={0}
                cy={0}
                r={1}
                gradientTransform="matrix(1.77776 0 0 2.6167 8.008 2.458)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.71} stopColor="#B49167" />
                <stop offset={0.82} stopColor="#B08C60" />
                <stop offset={0.96} stopColor="#A67E4E" />
                <stop offset={0.99} stopColor="#A37A49" />
            </radialGradient>
            <radialGradient
                id="couple_svg__g"
                cx={0}
                cy={0}
                r={1}
                gradientTransform="matrix(1.77776 0 0 2.6167 8.008 2.458)"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#EDBE89" />
                <stop offset={0.569} stopColor="#EDBE89" />
                <stop offset={0.952} stopColor="#A67E4E" />
                <stop offset={1} stopColor="#A37A49" />
            </radialGradient>
            <radialGradient
                id="couple_svg__h"
                cx={0}
                cy={0}
                r={1}
                gradientTransform="matrix(0 .33459 -.95148 0 7.985 .67)"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#C4C4C4" />
                <stop offset={1} stopColor="#C4C4C4" stopOpacity={0} />
            </radialGradient>
            <radialGradient
                id="couple_svg__m"
                cx={0}
                cy={0}
                r={1}
                gradientTransform="matrix(1.25219 0 0 2.16629 4.11 6.592)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.49} stopColor="#EBBE89" />
                <stop offset={0.66} stopColor="#E9BC87" />
                <stop offset={0.75} stopColor="#E1B480" />
                <stop offset={0.82} stopColor="#D3A874" />
                <stop offset={0.88} stopColor="#C09663" />
                <stop offset={0.94} stopColor="#A87F4D" />
                <stop offset={0.95} stopColor="#A37A49" />
                <stop offset={0.99} stopColor="#A37A49" />
            </radialGradient>
            <radialGradient
                id="couple_svg__o"
                cx={0}
                cy={0}
                r={1}
                gradientTransform="matrix(1.33835 -.66917 .39732 .79464 3.68 4.974)"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#D2AB8D" />
                <stop offset={0.703} stopColor="#A66C4E" stopOpacity={0} />
            </radialGradient>
        </defs>
    </svg>
)
const ForwardRef = forwardRef(SvgCouple)
const Memo = memo(ForwardRef)
export default Memo
