import {
    type ComponentPropsWithoutRef,
    type SyntheticEvent,
    useState,
} from "react"
import "./PremiumPrivateRequest.scss"
import "../../common/atoms/ModalOption/ModalOption.scss"
import { gettext, interpolate } from "@multimediallc/web-utils"
import { Overlay } from "../../common"
import { ModalOption } from "../../common/atoms/ModalOption"

interface PremiumPrivateRequestProps extends ComponentPropsWithoutRef<"div"> {
    tokensPerMinute: number
    minimumMinutes: number
    premiumTokensPerMinute: number
    premiumMinimumMinutes: number
    allowRecording: boolean
    model: string
    toggleCallback: (premium: boolean) => void
    requestCallback: () => void
    closeCallback: () => void
}

const i18n = {
    standard: "Standard Private Show",
    premium: "Premium Private Show",
    defaultDesc: "Private time with the broadcaster",
    recordingDesc: "Recording of this show available in your collection",
    rate: (tokens: number, minutes: number): string => {
        return interpolate(
            gettext("%(tokens)s tokens/minute, minimum %(minutes)s minutes)"),
            { tokens: tokens, minutes: minutes },
            true,
        )
    },
    premiumTooltip: "No one can spy on the show",
}

export function PremiumPrivateRequest({
    tokensPerMinute,
    minimumMinutes,
    premiumTokensPerMinute,
    premiumMinimumMinutes,
    allowRecording,
    model,
    toggleCallback,
    requestCallback,
    closeCallback,
    ...props
}: PremiumPrivateRequestProps) {
    const [premium, setPremium] = useState<boolean>(false)
    const absorb = (e: SyntheticEvent) => {
        e.stopPropagation()
    }
    const selectPremium = () => {
        if (!premium) {
            setPremium(true)
            toggleCallback(true)
        }
    }
    const selectStandard = () => {
        if (premium) {
            setPremium(false)
            toggleCallback(false)
        }
    }
    const request = () => {
        requestCallback()
        closeCallback()
    }
    const details = []
    details.push(
        <div className="privateDetails" key="defaultDescription">
            <svg
                className="checkDiv"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
            >
                <path
                    d="M4.74341 11.8456C5.22032 12.3275 5.9943 12.3404 6.48694 11.8747L13.586 5.17965C13.923 4.87642 14.0695 4.41426 13.9687 3.97204C13.8679 3.52982 13.5357 3.17695 13.1006 3.04999C12.6655 2.92304 12.1959 3.04193 11.8734 3.36065L5.66064 9.21965L4.15091 7.69465C3.83881 7.36856 3.37525 7.23628 2.93826 7.34862C2.50127 7.46096 2.15879 7.80045 2.04234 8.23671C1.9259 8.67297 2.0536 9.13817 2.3764 9.45365L4.74341 11.8456Z"
                    fill="#009900"
                />
            </svg>
            <div className="descDiv">{i18n.defaultDesc}</div>
        </div>,
    )
    if (allowRecording) {
        details.push(
            <div className="privateDetails" key="recordingDescription">
                <svg
                    className="checkDiv"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                >
                    <path
                        d="M4.74341 11.8456C5.22032 12.3275 5.9943 12.3404 6.48694 11.8747L13.586 5.17965C13.923 4.87642 14.0695 4.41426 13.9687 3.97204C13.8679 3.52982 13.5357 3.17695 13.1006 3.04999C12.6655 2.92304 12.1959 3.04193 11.8734 3.36065L5.66064 9.21965L4.15091 7.69465C3.83881 7.36856 3.37525 7.23628 2.93826 7.34862C2.50127 7.46096 2.15879 7.80045 2.04234 8.23671C1.9259 8.67297 2.0536 9.13817 2.3764 9.45365L4.74341 11.8456Z"
                        fill="#009900"
                    />
                </svg>
                <div className="descDiv">{i18n.recordingDesc}</div>
            </div>,
        )
    }
    const optionDivs = []
    optionDivs.push(
        <ModalOption
            callback={selectStandard}
            title={i18n.standard}
            description={i18n.rate(tokensPerMinute, minimumMinutes)}
            selected={!premium}
            className="sOptionDiv"
            key="standardOption"
            data-testid="private-standard-option"
        />,
    )
    if (premiumTokensPerMinute > 0) {
        optionDivs.push(
            <ModalOption
                callback={selectPremium}
                title={i18n.premium}
                description={i18n.rate(
                    premiumTokensPerMinute,
                    premiumMinimumMinutes,
                )}
                selected={premium}
                className="sOptionDiv"
                key="premiumOption"
                data-testid="private-premium-option"
                tooltip={i18n.premiumTooltip}
            />,
        )
    }
    return (
        <div {...props}>
            <Overlay
                className="premiumPrivateRequest"
                data-testid="private-request-overlay"
                onClick={closeCallback}
            >
                <div className="premiumRequestModal" onClick={absorb}>
                    <div className="privateTitle">
                        Private Show with {model}
                    </div>
                    <div className="detailsDiv">{details}</div>
                    <div className="optionsDiv">{optionDivs}</div>
                    <svg
                        className="divider"
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="2"
                    >
                        <path d="M5000 1H0" stroke="#E0E0E0" />
                    </svg>
                    <div className="premiumButtonDiv">
                        <div
                            className="cancelButton"
                            onClick={closeCallback}
                            data-testid="private-cancel-button"
                        >
                            Cancel
                        </div>
                        <div
                            className="requestButton"
                            onClick={request}
                            data-testid="private-request-button"
                        >
                            Request
                        </div>
                    </div>
                </div>
            </Overlay>
        </div>
    )
}
