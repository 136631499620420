import { gettext } from "@multimediallc/web-utils"
import { Typography } from "../../common"
import "./OneClickFlowErrorPage.scss"
import { TranslationWithHandler } from "../../common/molecules/TranslationWithHandler"

export function OneClickFlowErrorPage({
    openPurchasePage,
}: {
    openPurchasePage: () => void
}) {
    const i18n = {
        purchaseError: gettext("Purchase error"),
        errorProcessing: gettext(
            "There was an error while processing your payment",
        ),
    }
    return (
        <>
            <Typography
                className="one-click-flow__scene-title"
                size="xlpx"
                weight="bold"
            >
                {i18n.purchaseError}
            </Typography>
            <div className="one-click-flow__error-page">
                <Typography className="md-error-text" size="mdpx">
                    {i18n.errorProcessing}
                </Typography>
                <TranslationWithHandler
                    text="Go to the <a id='purchase_page_link' target='_blank'>purchase page</a> for more info."
                    id="purchase_page_link"
                    handler={openPurchasePage}
                    className="sm-error-text"
                    style={{ marginTop: "15px" }}
                    size="smpx"
                />
            </div>
        </>
    )
}
