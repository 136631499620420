import type { ComponentPropsWithoutRef, SyntheticEvent } from "react"
import { useEffect, useRef, useState } from "react"
import { gettext, interpolate } from "@multimediallc/web-utils"
import { Overlay } from "../../common/atoms/Overlay"
import "./PrivateCancelEarlyOverlay.scss"

export interface IPrivateCancelEarlyProps
    extends ComponentPropsWithoutRef<"div"> {
    minimumMinutes: number
    tokensPerMinute: number
    secondsRemaining: number
    leaveCallback: () => void
    closeCallback: () => void
}

const i18n = {
    areYouSure: "Are You Sure?",
    explanation: (minutes: number, tokens: number): string => {
        let msg =
            "This show has a %(minimumMinutes)s minute minimum. Ending the show now will cost the remaining %(minimumTokens)s tokens."
        if (tokens < 1) {
            msg =
                "The show has reached its minimum duration. You may now end the show freely at any time."
        }
        return interpolate(
            gettext(msg),
            {
                minimumMinutes: minutes,
                minimumTokens: tokens,
            },
            true,
        )
    },
    remaining: (seconds: number): string => {
        let msg = "Time remaining: %(secondsRemaining)s secs."
        const minutes = Math.floor(seconds / 60)
        const displaySeconds = seconds - minutes * 60
        if (minutes > 0) {
            msg =
                "Time remaining: %(minutesRemaining)s mins and %(secondsRemaining)s secs."
        }
        return interpolate(
            gettext(msg),
            {
                minutesRemaining: minutes,
                secondsRemaining: displaySeconds,
            },
            true,
        )
    },
    cancel: "Cancel",
    leave: "Leave",
}

export function PrivateCancelEarlyOverlay({
    minimumMinutes,
    tokensPerMinute,
    secondsRemaining,
    leaveCallback,
    closeCallback,
    ...props
}: IPrivateCancelEarlyProps) {
    const cancelButton = useRef<HTMLDivElement>(null)
    const leaveButton = useRef<HTMLDivElement>(null)
    const [seconds, setSeconds] = useState(secondsRemaining)

    const tokens = Math.ceil((seconds * tokensPerMinute) / 60)

    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => {
                setSeconds(seconds - 1)
            }, 1000)
        }
        document.addEventListener("keydown", tabNav)
        return () => {
            document.removeEventListener("keydown", tabNav)
        }
    }, [seconds]) // eslint-disable-line react-hooks/exhaustive-deps

    const keyHandler = (e: React.KeyboardEvent) => {
        e.preventDefault()
        if (e.key === "Enter" || e.key === " ") {
            e.target.dispatchEvent(new MouseEvent("click", { bubbles: true }))
        }
    }

    const tabNav = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
            closeCallback()
        } else if (e.key === "Tab") {
            e.preventDefault()
            e.stopPropagation()
            if (document.activeElement === cancelButton.current) {
                leaveButton.current?.focus()
            } else {
                cancelButton.current?.focus()
            }
        }
    }

    const cancelClick = (e: SyntheticEvent) => {
        e.stopPropagation()
        closeCallback()
    }

    const leaveClick = (e: SyntheticEvent) => {
        e.stopPropagation()
        leaveCallback()
        closeCallback()
    }

    const absorb = (e: SyntheticEvent) => {
        e.stopPropagation()
    }

    return (
        <div {...props}>
            <Overlay className="cancelOverlay" onClick={cancelClick}>
                <div className="privateCancelEarlyOverlay" onClick={absorb}>
                    <div className="contentOuter">
                        <div className="contentInner">
                            <div className="textBox">
                                <div className="prompt">{i18n.areYouSure}</div>
                                <div className="explanation">
                                    {i18n.explanation(minimumMinutes, tokens)}
                                </div>
                                <div className="remaining">
                                    {i18n.remaining(seconds)}
                                </div>
                            </div>
                            <div className="buttonDiv">
                                <div
                                    className="cancelButton"
                                    onClick={cancelClick}
                                    onKeyDown={keyHandler}
                                    ref={cancelButton}
                                    tabIndex={0}
                                >
                                    {i18n.cancel}
                                </div>
                                <div
                                    className="leaveButton"
                                    onClick={leaveClick}
                                    onKeyDown={keyHandler}
                                    ref={leaveButton}
                                    tabIndex={0}
                                >
                                    {i18n.leave}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Overlay>
        </div>
    )
}
