import { useEffect, useState } from "react"
import "./OneClickFlow.scss"
import { gettext } from "@multimediallc/web-utils"
import { useAppContext } from "../../../hooks/appContext"
import { AlertProvider } from "../../../hooks/useAlert"
import { generateApiPath } from "../../../routes/util"
import { mergeClasses } from "../../../utils/css"
import { error } from "../../../utils/debug"
import { fetchGet, fetchPost } from "../../../utils/myFetch"
import { Button, ButtonSize } from "../../common"
import { ButtonTriangle } from "../../common/atoms/Icons/Others"
import { Spinner } from "../../common/atoms/Spinner"
import { OneClickFlowErrorPage } from "../OneClickFlowErrorPage"
import { OneClickFlowMain } from "../OneClickFlowMain"
import { OneClickFlowProducts } from "../OneClickFlowProductList"
import { OneClickFlowSuccessPage } from "../OneClickFlowSuccessPage"
import SwipeToConfirmButton from "../SwipeToConfirmButton"

interface OneClickFlowProps {
    source?: string
    roomType?: string
    desktop?: boolean
    tokenBalance?: number
    openPurchasePage: () => void
}

export const enum PAGES {
    MAIN = "main",
    PRODUCTS = "products",
    ERROR = "error",
    SUCCESS = "success",
}
export interface IBillingProduct {
    cost: string
    flat_bonus_tokens: number
    is_default: boolean
    percent_bonus_tokens: number
    product_id: string
    tokens: number
}

export enum OneClickPayMethod {
    SAVED_CARD = "savedcard",
    EPOCH_ONECLICK = "epochoneclick",
}

interface IPayMethodPayload {
    card_type: string
    cvv2_required: boolean
    expiration_date: string
    last4: string
    sub_id: string
}
export interface GetOneClickEligibleProductsResponse {
    eligible_plans: IBillingProduct[]
    paymethod: {
        method: OneClickPayMethod
        payload: Partial<IPayMethodPayload>
    }
    success: boolean
}

interface IOneClickAjaxPost {
    success: boolean
    error: string | null
}

export enum Source {
    main = "main",
    photo_videos = "TokenSourcePurchaseMedia",
}

const i18n = {
    completePurchase: gettext("Complete Purchase"),
}

// eslint-disable-next-line complexity
export function OneClickFlowNoLink({
    source = "",
    roomType,
    desktop = false,
    tokenBalance,
    openPurchasePage,
}: OneClickFlowProps) {
    const languageCode = useAppContext().context.language_code
    const [loading, setLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [page, setPage] = useState(PAGES.MAIN)
    const [data, setData] = useState<GetOneClickEligibleProductsResponse>()
    const [cvv, setCvv] = useState("")
    const [selectedProductId, setselectedProductId] = useState<string>()
    const cvvRequired = !!data?.paymethod?.payload?.cvv2_required

    const oneclickApiPath = `${generateApiPath(
        languageCode,
        "tipping",
        "oneclick_plans",
    )}${source ? `?source=${source}` : ""}`
    const onSuccess = async () => {
        if (cvvRequired && cvv?.length < 3) return
        setLoading(true)
        try {
            const subId = data?.paymethod?.payload?.sub_id
            const body = {
                payMethod: "savedcard",
                cvv2: cvv,
                savedcard_sub_id: subId ?? "",
                productID: selectedProductId!,
            }
            const res = await fetchPost(
                generateApiPath(
                    languageCode,
                    "tipping",
                    "ajax-oneclick-purchase/",
                ),
                body,
            )
            const responseData = res.jsonData as IOneClickAjaxPost
            if (!responseData?.success) {
                setPage(PAGES.ERROR)
            } else {
                setPage(PAGES.SUCCESS)
            }
        } catch (err) {
            setPage(PAGES.ERROR)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        const getProducts = () => {
            setLoading(true)
            fetchGet(oneclickApiPath)
                .then((resp) => {
                    const data =
                        resp.jsonData as GetOneClickEligibleProductsResponse
                    if (!data?.success) {
                        setIsError(true)
                    }
                    const defaultProduct = data.eligible_plans.find(
                        (p) => p.is_default,
                    )
                    if (defaultProduct) {
                        setselectedProductId(defaultProduct?.product_id)
                    } else {
                        setselectedProductId(
                            data.eligible_plans[data.eligible_plans.length - 1]
                                ?.product_id,
                        )
                    }
                    setData(data)
                })
                .catch((e) => {
                    error(e)
                })
                .finally(() => {
                    setLoading(false)
                })
        }

        getProducts()
    }, [oneclickApiPath])

    if (!data || !selectedProductId) {
        if (isError) {
            return (
                <div
                    className={`one-click-flow__container ${
                        source && source === Source.photo_videos && !desktop
                            ? "token-source-purchase-media"
                            : ""
                    }`}
                >
                    <OneClickFlowErrorPage
                        openPurchasePage={openPurchasePage}
                    />
                </div>
            )
        }
        return (
            <div className="one-click-flow__loading">
                <Spinner />
            </div>
        )
    }

    const products = data?.eligible_plans
    const selectedProduct = products?.find(
        (p) => p.product_id === selectedProductId,
    )
    const tokenAmount = selectedProduct?.tokens
    const payMethod = data.paymethod
    const cvvValidationFailed = cvvRequired && cvv.length < 3
    return (
        <AlertProvider>
            <div
                className={mergeClasses(
                    "one-click-flow__container",
                    source === Source.photo_videos && !desktop
                        ? "token-source-purchase-media"
                        : "",
                )}
                onTouchStart={(e) => e.stopPropagation()}
                onTouchEnd={(e) => e.stopPropagation()}
                onTouchMove={(e) => e.stopPropagation()}
                onDrag={(e) => e.stopPropagation()}
                onDragStart={(e) => e.stopPropagation()}
                onDragEnd={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
            >
                {loading && (
                    <div className="one-click-flow__loading">
                        <Spinner />
                    </div>
                )}
                {page === PAGES.MAIN && selectedProduct && (
                    <>
                        <OneClickFlowMain
                            setPage={setPage}
                            payMethod={payMethod}
                            roomType={roomType}
                            product={selectedProduct}
                            handleCvvChange={
                                cvvRequired
                                    ? (value: string) => setCvv(value)
                                    : undefined
                            }
                            desktop={desktop}
                            tokenBalance={tokenBalance}
                            openPurchasePage={openPurchasePage}
                        />
                        {!desktop ? (
                            <SwipeToConfirmButton
                                disabled={cvvValidationFailed}
                                onSuccess={() => void onSuccess()}
                            />
                        ) : (
                            <Button
                                className="complete-purchase-button"
                                size={ButtonSize.Medium}
                                text={i18n.completePurchase}
                                onClick={() => void onSuccess()}
                                disabled={cvvValidationFailed}
                                icon={
                                    <ButtonTriangle
                                        style={{ marginLeft: "8px" }}
                                    />
                                }
                            />
                        )}
                    </>
                )}
                {page === PAGES.PRODUCTS && (
                    <OneClickFlowProducts
                        setPage={setPage}
                        setSelected={setselectedProductId}
                        selectedProduct={selectedProductId}
                        products={products}
                    />
                )}

                {page === PAGES.SUCCESS && tokenAmount && (
                    <OneClickFlowSuccessPage tokenAmount={tokenAmount} />
                )}
                {page === PAGES.ERROR && (
                    <OneClickFlowErrorPage
                        openPurchasePage={openPurchasePage}
                    />
                )}
            </div>
        </AlertProvider>
    )
}
