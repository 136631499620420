import { useCallback, useState } from "react"
import "./RoomFlagNotification.scss"
import "flag-icons/css/flag-icons.min.css"
import { gettext, interpolate } from "@multimediallc/web-utils"
import { renderToStaticMarkup } from "react-dom/server"

const i18n = {
    activate: gettext("Activate"),
    youCanAttractMoreViewers: (flagIcon: string) => {
        return interpolate(
            gettext(
                "You may attract more viewers by showcasing your country flag (%(flagIcon)s) on the home page.",
            ),
            { flagIcon: flagIcon },
            true,
        )
    },
    roomFlagNotificationSubject: gettext("Get more viewers!"),
    boostTrafficMessage: gettext(
        "Try this out to see if it boosts your traffic.",
    ),
}

type RoomFlagNotificationProps = {
    countryCode: string
    countryName: string
    onActivate: () => void
    onDismiss: () => void
    positioningEl?: HTMLElement
}

export function RoomFlagNotification({
    countryCode,
    countryName,
    onActivate,
    onDismiss,
    positioningEl,
    ...props
}: RoomFlagNotificationProps) {
    const [bottom, setBottom] = useState(0)
    const [left, setLeft] = useState(0)

    const updatePositioning = useCallback(
        (
            node: HTMLDivElement | null,
            positioningEl: HTMLElement | undefined,
        ): void => {
            if (node !== null && positioningEl !== undefined) {
                setBottom(positioningEl.offsetHeight * 1.25)
                setLeft(
                    positioningEl.offsetLeft -
                        node.getBoundingClientRect().width / 2 +
                        positioningEl.offsetWidth / 2,
                )
            }
        },
        [],
    )

    const createFlagIcon = () => {
        return (
            <span
                className={`fi fi-${countryCode}`}
                title={`${countryName}`}
                data-testid="room-flag-notification-flag-icon"
            />
        )
    }

    const getFlagIconMarkup = () => {
        return {
            __html: i18n.youCanAttractMoreViewers(
                renderToStaticMarkup(createFlagIcon()),
            ),
        }
    }

    return (
        <div
            className="RoomFlagNotification"
            data-testid="room-flag-notification"
            ref={(el: HTMLDivElement) => {
                updatePositioning(el, positioningEl)
            }}
            style={{
                bottom: positioningEl !== undefined ? `${bottom}px` : "",
                left: positioningEl !== undefined ? `${left}px` : "",
            }}
            {...props}
        >
            <div className="RoomFlagNotification__subject-container">
                <div className="RoomFlagNotification__subject">
                    <span>🎉</span> {i18n.roomFlagNotificationSubject}
                </div>
                <div
                    className="RoomFlagNotification__close-button"
                    data-testid="room-flag-notification-dismiss"
                    onClick={() => {
                        onDismiss()
                    }}
                    tabIndex={0}
                />
            </div>
            <div className="RoomFlagNotification__message">
                <div
                    className="RoomFlagNotification__flag-display-container"
                    dangerouslySetInnerHTML={getFlagIconMarkup()}
                />

                {i18n.boostTrafficMessage}
            </div>
            <div className="RoomFlagNotification__button-container">
                <div
                    className="RoomFlagNotification__button"
                    data-testid="room-flag-notification-activate"
                    onClick={() => {
                        onActivate()
                    }}
                    tabIndex={0}
                >
                    {i18n.activate}
                </div>
            </div>
        </div>
    )
}
